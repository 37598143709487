<template lang="pug">
  b-row(align-h="center" align-v="center" class="min-vh-100")
    b-col(cols="6")
      h1 Bienvenue
      p(class="lead") Veuillez vous connecter pour continuer.
      b-card(align="left")
        div(class="text-center m-4")
          //- b-avatar(size="6rem")
          b-img(src="@/assets/logo-cairn-mobile.png" height="100px")
        b-form(@submit.prevent="login")
          //- Groupe email
          b-form-group(label="Identifiant" :state="!$v.identifier.$invalid")
            b-form-input(
              v-model="identifier"
              :state="!$v.identifier.$invalid"
              type="text"
              placeholder="Entrez l'identifiant"
            )
            template(#invalid-feedback)
              template(v-if="!$v.identifier.required")
                | Ne peut pas être vide
              template(v-else)
                | Doit être une adresse email valide
          //- Groupe mot de passe
          b-form-group(label="Mot de passe" :state="!$v.password.$invalid")
            b-form-input(
              v-model="password"
              :state="!$v.password.$invalid"
              type="password"
              placeholder="Entrez le mot de passe"
            )
            template(#invalid-feedback)
              template(v-if="!$v.password.required")
                | Ne peut pas être vide
              template(v-else)
                | Au moins 8 caractères
            //- template(v-slot:description)
            //-   a(href="#") Mot de passe oublié ?
          //- Groupe Se souvenir de moi
          b-form-group
              b-form-checkbox(v-model="rememberMe" required) Se souvenir de moi la prochaine fois
          //- Bouton envoi
          div(class="text-center")
            b-button(type="submit" :variant="!$v.$invalid ? 'success' : 'secondary'" :disabled="$v.$invalid")
              | Me connecter
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { boolean } from '@/services/validators';

export default {
  data: () => ({
    identifier: null,
    password: null,
    rememberMe: false,
  }),
  validations: {
    identifier: {
      required,
    },
    password: {
      required,
      minLength: minLength(6),
    },
    rememberMe: {
      boolean,
    },
  },
  computed: {},
  methods: {
    login() {
      this.$store.dispatch('auth/token/login', {
        identifier: this.identifier,
        password: this.password,
        rememberMe: this.rememberMe,
      }).then(() => {
        this.$router.push(this.$route.params.nextUrl ?? '/');
      });
    },
  },
};
</script>
