import { setupRoutes as setupRoutesLicence } from './licence';
import { setupRoutes as setupRoutesInstitution } from './institution';
import partenairesStore from './store';

export function setupRoutes(params) {
  return () => {
    params.group({ prefix: '/licence', name: 'licence' }, setupRoutesLicence(params));
    params.group({ prefix: '/institution', name: 'institution' }, setupRoutesInstitution(params));
  };
}

export function setupStore() {
  return partenairesStore;
}
