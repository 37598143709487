/* eslint-disable no-param-reassign */
export function pluralize(string, amount) {
  let isPlural = false;
  if (_.isUndefined(amount)) isPlural = true;
  if (_.isArray(amount)) amount = amount.length;
  if (_.isNumber(amount) && amount > 1) isPlural = true;
  return isPlural ? `${string}s` : string;
}

export function toUrl(baseUrl, params) {
  const url = new URL(baseUrl);
  _.each(([key, value]) => url.searchParams.append(key, value))(_.toPairs(params));
  return url.href;
}

export const StringVue = {
  install(Vue) {
    Vue.filter('pluralize', pluralize);
    Vue.filter('toUrl', toUrl);
  },
};
