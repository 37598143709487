export default {
  locale: {
    date: 'fr',
  },
  api: {
    baseURL: process.env.NODE_ENV === 'production'
      ? 'https://partenaires.cairn.info/api'
      : 'http://localhost:5000',
    timeout: 1000 * 60 * 2, // 2 minutes
  },
  routesModules: {
    '/partenaires': 'partenaires',
  },
};
