import Vue from 'vue';

import authStore from './store';
import LoginView from './login.vue';

export function setupRoutes({ route, router, store }) {
  // Routes
  const buildRoutes = () => {
    route('/login', LoginView)
      .name('login')
      .meta({ requiredAuth: false });
  };

  // Vérifie si un utilisateur est authentifié
  router.beforeEach(async (to, from, next) => {
    // Déjà authentifié
    if (store.state.auth.me !== null) return next();
    // Pas besoin d'authentifier
    if (_.some(to.matched, (m) => m.meta.requiredAuth === false)) return next();
    // Vérification du token
    const token = localStorage.getItem('cairn:partenaires:user-token');
    if (token) {
      try {
        // Le token existe, chargement du profil
        await store.dispatch('auth/token/login', { token });
        // L'utilisateur peut continuer sa route
        return next();
      } catch (err) {
        // Le token a expiré, on affiche une popup à l'utilisateur
        const vm = new Vue();
        vm.$bvToast.toast(_.get('response.data.msg')(err), {
          title: 'La session a expirée',
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
        });
      }
    }
    if (to.name === 'auth.login') return next();
    // Redirection vers la page de login, en fournissant ce qu'il faut pour rediriger vers l'adresse actuelle
    return next({
      name: 'auth.login',
      params: {
        nextUrl: to.fullPath,
      },
    });
  });

  return buildRoutes;
}

export const setupStore = () => authStore;
