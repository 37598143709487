<template lang="pug">
  router-view
</template>

<script>
export default {
  data: () => ({
  }),
  computed: {
  },
  methods: {
  },
};
</script>

<style lans="sass">
</style>
