/* eslint-disable global-require, import/no-dynamic-require */
import Vue from 'vue';
import Vuex from 'vuex';
import Router from 'vue-router';
import {
  Factory,
  group,
  redirect,
  route,
} from 'vue-routisan';

import settings from '@/config';
import {
  setupRoutes as setupRoutesDashboard,
  setupStore as setupStoreDashboard,
} from '@/modules/dashboard';
import {
  setupRoutes as setupRoutesAuth,
  setupStore as setupStoreAuth,
} from '@/modules/auth';

// Instanciation du routeur
Vue.use(Router);
export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
});
// Instanciation du store
Vue.use(Vuex);
export const store = new Vuex.Store();

const paramsSetupRoutes = {
  group,
  redirect,
  route,
  router,
  store,
};
const paramsSetupStore = {
  router,
  store,
};

// Mise en place des modules de bases
// Dashboard
const dashboardRoute = setupRoutesDashboard(paramsSetupRoutes)();
store.registerModule('dashboard', setupStoreDashboard(paramsSetupStore));
// Authentification
group({ prefix: '/auth', name: 'auth' }, setupRoutesAuth(paramsSetupRoutes));
store.registerModule('auth', setupStoreAuth(paramsSetupStore));

function setupModule([url, moduleConf]) {
  const moduleName = _.isString(moduleConf) ? moduleConf : moduleConf.module;
  const module = require(`@/modules/${moduleName}`);

  // Setup du store
  if (_.has('setupStore')(module)) {
    const storeModule = module.setupStore(paramsSetupStore);
    store.registerModule(moduleName, storeModule);
  }

  // Setup du routing
  if (_.has('setupRoutes')(module)) {
    const setupGroupRoutes = module.setupRoutes(paramsSetupRoutes);
    dashboardRoute.children(() => {
      group({ prefix: url, name: moduleName }, setupGroupRoutes);
    });
  }
}

// Définition des modules
_.forEach(setupModule)(_.toPairs(settings.routesModules));

// Mise en place des routes dans le routeur
router.addRoutes(Factory.routes());
