export default {
  namespaced: true,
  state: {
    resources: null,
  },
  mutations: {
    setResources(state, resources) {
      state.resources = resources;
    },
  },
  actions: {
    async getResources({ state, commit }, { type }) {
      if (state.resources !== null) return state.resources;
      const resources = await this.$api.partenaires.getResources(type);
      commit('setResources', resources);
      return state.resources;
    },
  },
  getters: {
  },
  modules: {
  },
};
