<template lang="pug">
  div
    router-view/
</template>

<script>
export default {
  data: () => ({
  }),
  created() {
  },
  methods: {
  },
};
</script>
