export default {
  namespaced: true,
  state: {
    apiToken: null,
  },
  mutations: {
    setToken(state, token) {
      localStorage.setItem('cairn:partenaires:user-token', token);
      state.token = token;
    },
    deleteToken(state) {
      localStorage.removeItem('cairn:partenaires:user-token');
      state.token = null;
    },
  },
  actions: {
    // eslint-disable-next-line object-curly-newline
    async login({ commit, dispatch }, { identifier, password, token, rememberMe }) {
      let authToken = token;
      if (!authToken) {
        const auth = await this.$api.auth.login({ identifier, password, rememberMe });
        authToken = auth.token;
      }
      commit('setToken', authToken);
      return dispatch('auth/getMe', null, { root: true });
    },
    logout({ commit }) {
      commit('deleteToken');
      commit('auth/deleteMe', null, { root: true });
    },
  },
  getters: {},
};
