export default {
  namespaced: true,
  state: {
    isBusy: false,
  },
  mutations: {
    setBusy(state, isBusy) {
      state.isBusy = isBusy;
    },
  },
  actions: {
  },
  getters: {
  },
  modules: {
  },
};
