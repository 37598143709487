/* eslint-disable no-param-reassign */
export default {
  install(Vue) {
    const vm = new Vue();
    const defaultParams = {
      toaster: 'b-toaster-bottom-right',
      autoHideDelay: 2500,
    };

    // Permet de notifier l'utilisateur de manière standardiser
    function notify(message, titleOrOptions, options) {
      if (_.isString(titleOrOptions)) {
        titleOrOptions = { title: titleOrOptions };
      }
      if (_.isLikeFalse(titleOrOptions)) titleOrOptions = {};
      const params = _.mergeAll([
        defaultParams,
        titleOrOptions,
        options || {},
      ]);
      return vm.$bvToast.toast(message, params);
    }

    // Factory pour la création de fonctions dérivées
    const variantNotify = _.partial((message, titleOrOptions, options, variant) => {
      options = _.mergeAll([{ variant }, options || {}]);
      return notify(message, titleOrOptions, options);
    });

    Vue.prototype.$notify = {
      custom: notify,
      default: variantNotify([_, _, _, 'default']),
      primary: variantNotify([_, _, _, 'primary']),
      secondary: variantNotify([_, _, _, 'secondary']),
      info: variantNotify([_, _, _, 'info']),
      success: variantNotify([_, _, _, 'success']),
      warning: variantNotify([_, _, _, 'warning']),
      danger: variantNotify([_, _, _, 'danger']),
    };
  },
};
