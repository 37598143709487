// Imports from external libraries
import Vue from 'vue';
import Meta from 'vue-meta';
import QuerySynchronizer from '@oarepo/vue-query-synchronizer';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import VueProgressBar from 'vue-progressbar';

// Import from local code
import App from './App.vue';
import CairnApi from './api/http';
import Notification from './services/notification';
import { router, store } from './modules-factory';
import settings from './config';
import { DateVue } from './services/date-vue';
import { StringVue } from './services/string';
import { isLikeTrue, isLikeFalse, isFile } from './services/validators';

// Les bibliothèques disponibles de manière globale
window._ = _;

/*
####################################################################################################
# Mise en place des mixins pour lodash
####################################################################################################
*/
_.mixin({ isLikeTrue, isLikeFalse, isFile });

/*
####################################################################################################
# Mise en place de vue et de ses plugins
####################################################################################################
*/
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(CairnApi, settings, store, router);
Vue.use(Notification);
Vue.use(DateVue, { lang: 'fr' });
Vue.use(StringVue);
Vue.use(Meta);
Vue.use(QuerySynchronizer, { router });
Vue.use(Vuelidate);
Vue.use(VueProgressBar, {
  thickness: '4px',
  color: '#17a2b8',
});

export default new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
