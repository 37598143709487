<template lang="pug">
  b-modal(
    v-bind="$attrs"
    v-on="$listeners"
    title="Importer un fichier Excel"
    size="xl"
    body-class="position-static"
    @hidden="spreadsheet = []"
    :ok-disabled="!showSpreadsheet"
    @ok="onSave"
  )
    template(v-if="!showSpreadsheet")
      p
        | Vous pouvez déposer ici un fichier Excel (*.xls, *.xlsx) permettant d'importer un lot
        | de plusieurs {{ subscriberLabel|pluralize }}.
        |
        | La feuille doit strictement contenir les colonnes suivantes avec en-tête,
        | les astérisques rouges signifiant des cellules non vides :
      ul
        li
          | Adresse email
          span(class="text-danger") *
        li Nom
        li Prénom
        li Référence
        li Début d'accès
        li Fin d'accès
      p.
        <b>Remarque</b><br />
        Seule l'adresse email est obligatoire.<br />
        Si la colonne "Début d'accès" est vide, le système la remplira automatiquement
          avec la valeur de la date du jour.<br />
        Si la colonne "Fin d'accès" est vide, le système la remplira automatiquement
          avec la valeur de la date du jour +365.
      b-form-file(
        size="lg"
        placeholder="Envoyer votre fichier"
        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        @change="onLoad"
        browse-text="…"
      )
      b-alert(ref="errorAlert" variant="danger" fade class="mt-3")
        | Erreur lors de l'analyse du fichier, vérifiez les colonnes.
    template(v-else)
      p
        | Ci-dessous, les données qui ont été extraites depuis le fichier et qui seront importées.
      b-table-lite(
        :fields="fields"
        :items="spreadsheet"
        small
        striped
        bordered
        :tbody-tr-class="defineRowClass"
      )
        template(#cell(email)="data")
          | {{ data.value }}
          b-badge(v-if="data.item.id" variant="info" class="ml-1 float-right") existe
        template(#cell(idResource)="data")
          template(v-if="indexedResourcesById[data.value]") {{ indexedResourcesById[data.value].text }}
          template(v-else): i Ressource non trouvée
    b-overlay(
      no-wrap
      rounded
      spinner-type="grow"
      spinner-variant="info"
      :show="busy"
    )
</template>

<script>
/*
  Initialement, il était question d'utiliser la bibliothèque javascript SheetJS js-xlsx
  qui est très bien dans l'absolue, mais qui prend quasiment 1Mb une fois minifée
  (environ la moitié du client :O ), alors qu'il est question d'utiliser juste 1% des capacités de la bibliothèque.
  Si par la suite, il s'avère qu'il y a un besoin important de manipuler de l'excel dans
  cette interface, on revisera la situation. Mais en attendant, on préfère passer par python
  pour ne pas avoir un client trop lourd en ressource.
 */

export default {
  inheritAttrs: false,
  props: {
    resourceType: {
      type: String,
      required: true,
    },
    resource: {
      type: Object,
      required: true,
    },
    resources: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    spreadsheet: [],
    busy: false,
  }),
  computed: {
    indexedResourcesByKey: (vm) => _.keyBy('key')(vm.resources),
    indexedResourcesById: (vm) => _.keyBy('value')(vm.resources),
    showSpreadsheet: (vm) => _.isLikeTrue(vm.spreadsheet),
    subscriberLabel: (vm) => (vm.resourceType === 'licence' ? 'abonné' : 'membre'),
    apiModule: (vm) => (vm.resourceType === 'licence' ? vm.$api.partenaires.licence : vm.$api.partenaires.institution),
    fields: (vm) => {
      const subscribers = vm.spreadsheet;
      const fields = [
        {
          label: 'Adresse email',
          key: 'email',
        },
        {
          label: 'Nom',
          key: 'lastName',
        },
        {
          label: 'Prénom',
          key: 'firstName',
        },
        {
          label: 'Référence',
          key: 'customCode',
        },
        {
          label: 'Début',
          key: 'beginAccess',
        },
        {
          label: 'Fin',
          key: 'endAccess',
        },
      ];
      if (_.some('idResource')(subscribers)) {
        // Si les données importées contiennent une colonne vers une ressource précise
        fields.push({
          label: vm.resourceType === 'licence' ? 'Revue' : 'Institution',
          key: 'idResource',
        });
      }
      return fields;
    },
  },
  methods: {
    async onLoad(ev) {
      let subscribers;
      try {
        this.busy = true;
        subscribers = await this.$api.partenaires.spreadsheet.toJson(ev.target.files[0], this.resource.value);
      } catch (err) {
        this.$refs.errorAlert.show = 5;
        throw err;
      } finally {
        this.busy = false;
      }
      subscribers = _.map((subscriber) => {
        if (this.indexedResourcesById[subscriber.idResource]) {
          subscriber.idResource = this.indexedResourcesById[subscriber.idResource].value;
        } else {
          // TODO: Un message d'erreur
          subscriber.idResource = null;
          subscriber.hasError = true;
        }
        delete subscriber.resourceKey;
        return subscriber;
      })(subscribers);
      this.spreadsheet = subscribers;
    },
    async onSave(ev) {
      ev.preventDefault();
      this.busy = true;
      const spreadsheet = _.reject(_.get('hasError'))(this.spreadsheet);
      const subscribers = [];
      const groupSubscribers = _.groupBy((subscriber) => (!subscriber.id ? 'new' : 'update'))(spreadsheet);
      try {
        if (groupSubscribers.new) {
          groupSubscribers.new = _.map(_.omit(['id']))(groupSubscribers.new);
          const newSubscribers = await this.apiModule.saveNewSubscribers(groupSubscribers.new);
          subscribers.push(...newSubscribers);
        }
        if (groupSubscribers.update) {
          groupSubscribers.update = _.map(_.omit(['idResource']))(groupSubscribers.update);
          const updatedSubscribers = await this.apiModule.saveSubscribers(groupSubscribers.update);
          subscribers.push(...updatedSubscribers);
        }
        this.$emit('save', subscribers);
        this.$bvModal.hide(ev.componentId);
      } finally {
        this.busy = false;
      }
    },
    // eslint-disable-next-line consistent-return
    defineRowClass(subscriber, type) {
      if (!subscriber || type !== 'row') return '';
      if (subscriber.idResource === null) return 'table-danger';
    },
  },
};
</script>

<style lans="sass">
</style>
