export default ($http) => ({
  async login({ identifier, password, rememberMe }) {
    const response = await $http.post('/auth/login', {
      identifier,
      password,
      rememberMe,
      type: 'email',
    });
    return response.data;
  },
});
