import { Guard, Factory } from 'vue-routisan';

import dashboardStore from './store';
import DashboardView from './dashboard.vue';

export function setupRoutes({ route, store }) {
  /*
    Ce qui suit est du code temporaire pour la redirection automatique vers la gestion des abonnées licence/institution.
    Pour l'instant, il n'y a qu'un seul module accessible à un utilisateur, ça n'a donc aucun sens de lui proposer
    une page d'accueil.

    Quand il y aura plusieurs modules, ce Guard pourra être supprimé.
  */
  class RootRedirectGuard extends Guard {
    // eslint-disable-next-line class-methods-use-this
    async handle(resolve, reject, { to }) {
      // Si le chemin n'est pas la racine, on ignore la garde
      if (to.path !== '/') return resolve();
      // Vérification du nombre de types différents accessible à l'utilisateur
      const resources = await store.dispatch('partenaires/getResources', { type: ['licence', 'institution'] });
      const uniqResources = _.flow(
        _.map(_.get('type')),
        _.uniq,
      )(resources);
      // Si l'utilisateur ne peut accéder qu'à un seul type de ressource, redirection automatique vers elle
      if (uniqResources.length === 1) return reject({ name: `partenaires.${uniqResources[0]}.subscribers` });
      return resolve();
    }
  }
  Factory.withGuards({
    RootRedirectGuard,
  });

  // Définition de la route
  return () => route('/', DashboardView).guard('RootRedirectGuard');
}

export function setupStore() {
  return dashboardStore;
}
