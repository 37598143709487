import token from './store.token';

export default {
  namespaced: true,
  state: {
    me: null,
  },
  mutations: {
    setMe(state, user) {
      state.me = user;
    },
    deleteMe(state) {
      state.me = null;
    },
  },
  actions: {
    async getMe({ state, commit, dispatch }) {
      if (state.me !== null) return state.me;
      let user;
      try {
        user = await this.$api.user.getMe();
      } catch (error) {
        return dispatch('token/logout');
      }
      commit('setMe', user);
      return user;
    },
  },
  getters: {},
  modules: {
    token,
  },
};
