<template lang="pug">
  b-modal(
    title="Attention"
    header-bg-variant="warning"
    v-bind="$attrs"
    v-on="$listeners"
    @ok="save"
  )
    p
      | Vous êtes sur le point de modifier les périodes d'accès sur Cairn.info
      | à {{ resourceType === 'licence' ? "la revue" : "l'institution" }} #[em {{ resource.text }}]
      | pour #[strong {{ subscribers.length }}] {{ subscriberLabel|pluralize(subscribers) }}.
      | Cette action est immédiate.
    template(v-if="resourceType === 'licence'")
      b-form-group(label="Accès par")
        b-form-radio-group(v-model="checkAccessType")
          b-form-radio(value="date") Dates
          b-form-radio(value="issue") Numéros
    template(v-if="resourceType === 'licence' && checkAccessType === 'issue'")
      b-form-group
        b-row
          b-col(cols="6")
            b-form-group(description="Début d'accès")
              b-form-input(type="text" v-model="beginAccess")
          b-col(cols="6")
            b-form-group(description="Fin d'accès")
              b-form-input(type="text" v-model="endAccess")
    template(v-else)
      b-form-group
        b-row
          b-col(cols="6")
            b-form-group(description="Début d'accès")
              b-form-input(type="date" v-model="beginAccess")
          b-col(cols="6")
            b-form-group(description="Fin d'accès")
              b-form-input(type="date" v-model="endAccess")
</template>

<script>
export default {
  props: {
    resourceType: {
      type: String,
      required: true,
    },
    resource: {
      type: Object,
      required: true,
    },
    subscribers: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    beginAccess: null,
    endAccess: null,
    title: null,
    checkAccessType: 'date',
  }),
  computed: {
    subscriberLabel: (vm) => (vm.resourceType === 'licence' ? 'abonné' : 'membre'),
    apiModule: (vm) => (vm.resourceType === 'licence' ? vm.$api.partenaires.licence : vm.$api.partenaires.institution),
  },
  methods: {
    async save() {
      if (!this.beginAccess && !this.endAccess) return;
      // Prépation des données à envoyer à l'api
      let subscribers = _.map((subscriber) => {
        const newSubscriber = { id: subscriber.id };
        if (this.beginAccess) newSubscriber.beginAccess = this.beginAccess;
        if (this.endAccess) newSubscriber.endAccess = this.endAccess;
        if (this.resourceType === 'licence') newSubscriber.checkAccessType = this.checkAccessType;
        return newSubscriber;
      })(this.subscribers);
      // Envoi à l'api
      subscribers = await this.apiModule.saveSubscribers(subscribers);
      // Signalement de la bonne opération à l'utilisateur
      this.$emit('save', subscribers);
      this.$bvToast.toast(`${subscribers.length} membres modifiés !`, {
        title: 'Opération réussie !',
        variant: 'success',
        autoHideDelay: 2500,
      });
    },
  },
};
</script>

<style lans="sass">
</style>
