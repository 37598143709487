import indexView from './index.vue';
import subscribersView from '../subscribers-licence.vue';

export function setupRoutes({ route }) {
  return () => {
    route('/', indexView).children(() => {
      route('/subscribers', subscribersView)
        .name('subscribers')
        .prop('resourceType', 'licence')
        .meta('query', {
          sortBy: 'string:last_name',
          sortDesc: 'bool:false',
          // Les filtres
          email: '',
          first_name: '',
          last_name: '',
          custom_code: '',
          check_access_type: '.',
          begin_access: '',
          end_access: '',
          resource: '',
          status: '.',
          id_subscriber: '',
        });
    });
  };
}

export function setupStore() {
}
