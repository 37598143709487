import { date } from '@/services/date-vue';

function convertSubscribers(subscriber) {
  if (_.isArray(subscriber)) return _.map(convertSubscribers)(subscriber);
  const parsedSubscriber = _.flow(
    _.pick(['createdAt', 'modifiedAt']),
    _.mapValues(date),
    _.merge(subscriber),
  )(subscriber);
  return parsedSubscriber;
}

export default ($http) => {
  async function getSubscribers(module, idResources) {
    const response = await $http.get(`partenaires/${module}/subscribers`, {
      params: { idResource: idResources },
    });
    return convertSubscribers(response.data);
  }

  async function saveNewSubscribers(module, subscribers) {
    const response = await $http.post(`partenaires/${module}/subscribers`, { subscribers });
    return convertSubscribers(response.data);
  }

  async function saveSubscribers(module, subscribers) {
    const response = await $http.put(`partenaires/${module}/subscribers`, { subscribers });
    return convertSubscribers(response.data);
  }

  async function removeSubscribers(module, idSubscribers) {
    const response = await $http.delete(`partenaires/${module}/subscribers`, { data: { idSubscribers } });
    return convertSubscribers(response.data);
  }

  return {
    async getResources(type) {
      const params = {};
      if (type) params.type = type;
      const response = await $http.get('/partenaires/resources', { params });
      return response.data;
    },
    licence: {
      getSubscribers: _.partial(getSubscribers)(['licence']),
      saveNewSubscribers: _.partial(saveNewSubscribers)(['licence']),
      saveSubscribers: _.partial(saveSubscribers)(['licence']),
      removeSubscribers: _.partial(removeSubscribers)(['licence']),
      async changeAccessSubscribers(subscribers) {
        const response = await $http.put('/partenaires/licence/change-access-subscribers', { subscribers });
        return convertSubscribers(response.data);
      },
      async pendingSubscribers(idSubscribers) {
        const params = { idSubscribers };
        const response = await $http.post('/partenaires/licence/pending-subscribers', params);
        return convertSubscribers(response.data);
      },
    },
    institution: {
      getSubscribers: _.partial(getSubscribers)(['institution']),
      saveNewSubscribers: _.partial(saveNewSubscribers)(['institution']),
      saveSubscribers: _.partial(saveSubscribers)(['institution']),
      removeSubscribers: _.partial(removeSubscribers)(['institution']),
    },
    spreadsheet: {
      async toJson(spreadsheet, idResource = null) {
        let params;
        const headers = {};
        if (_.isFile(spreadsheet)) {
          params = new FormData();
          params.append('spreadsheet', spreadsheet);
          if (idResource) params.append('idResource', idResource);
          headers['Content-Type'] = 'multipart/form-data';
        } else {
          params = { spreadsheet };
        }
        const response = await $http.post('/partenaires/spreadsheet/to-json', params, headers);
        return response.data;
      },
      async fromSubscribers(subscribers) {
        const response = await $http.post(
          '/partenaires/spreadsheet/from-subscribers',
          { spreadsheet: subscribers },
          { responseType: 'arraybuffer' },
        );
        return response;
      },
    },
  };
};
