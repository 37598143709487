/* eslint-disable no-param-reassign */
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.locale('fr');
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
export const date = dayjs;

export function dateFormat(message, ...args) {
  // eslint-disable-next-line prefer-rest-params
  let fmt;
  if (_.isDate(message)) {
    message = dayjs(message);
    fmt = args;
  } else if (_.isString(message)) {
    message = dayjs(message, args[0]);
    fmt = _.tail(args);
  } else if (_.has('format')) {
    fmt = args;
  } else {
    throw new Error('Wrong type for dateFormat filter');
  }
  return message.format(...fmt);
}

export const DateVue = {
  install(Vue) {
    // dayjs.locale('fr');
    // dayjs.extend(customParseFormat);
    Vue.prototype.$date = date;
    Vue.prototype.$today = date();
    Vue.filter('date', dateFormat);
  },
};
