<template lang="pug">
  b-row

    b-overlay(
      no-wrap
      variant="light"
      spinner-variant="success"
      fixed
      :show="isBusy"
    )

    b-container.d-none(fluid v-if="hasRevue" id="network")
      b-nav(align="right")
        b-nav-text
          b-icon(icon="diagram2" font-scale="1.25")
        b-nav-item(:href="statistiquesUrl") Statistiques
        b-nav-text.active Gestion d'accès

    //- <!-- Contenu -->
    b-container(
      id="root-container"
      class="pr-0 min-vh-100 bg-light"
      fluid
      :class="{'collapsed': collapsed }"
      :style="{paddingLeft: collapsed ? sidebarWidth.collapsed : sidebarWidth.expended}"
    )

      b-navbar(id="root-navbar" type="light" variant="light" class="bg-white shadow-sm mb-5 bg-white")
        b-navbar-brand
          img(src="@/assets/logo-cairn-mobile.png" class="inline-block mr-2" style="max-height: 2rem")
          | Espace partenaires
        //- <!-- Menu utilisateur -->
        b-navbar-nav(small class="ml-auto")
          b-nav-item-dropdown(:no-caret="true" right)
            a(slot="button-content" class="text-decoration-none text-body" v-if="me")
              | {{ me.firstName }} {{ me.lastName }}
              b-avatar(class="ml-2")
            //- b-dropdown-item(href="#") Mon profil
            b-dropdown-item(href="#" @click="logout") Se déconnecter
      router-view
      //- Bar de progression pour ajax
      //- TODO: Pour l'instant, j'utilise vue-progress par comodité, mais la lib n'est plus MàJ depuis 2018
      vue-progress-bar
      b-progress(
        v-if="false"
        id="ajax-progress"
        value="75"
        variant="info"
        class="fixed-top"
        striped animated
        height="0.5rem"
      )
</template>

<script>
import { mapState } from 'vuex';
import { SidebarMenu } from 'vue-sidebar-menu';

import { toUrl } from '@/services/string';

export default {
  components: { SidebarMenu },
  data: () => ({
    collapsed: true,
    sidebarWidth: {
      // expended: '15rem',
      expended: '0rem',
      // collapsed: '3rem',
      collapsed: '0rem',
    },
    menu: [
      {
        header: true,
        title: 'Administration',
        hiddenOnCollapse: true,
      },
      {
        href: '/',
        title: 'Accueil',
        icon: {
          element: 'b-icon',
          attributes: {
            variant: 'success',
            icon: 'house',
            scale: 0.8,
          },
        },
      },
      {
        href: '/partenaires/licence/subscribers',
        title: 'Revue abonnés',
        icon: {
          element: 'b-icon',
          attributes: {
            variant: 'info',
            icon: 'people',
            scale: 0.8,
          },
        },
      },
      {
        href: '/partenaires/institution/subscribers',
        title: 'Membres institution',
        icon: {
          element: 'b-icon',
          attributes: {
            variant: 'info',
            icon: 'people',
            scale: 0.8,
          },
        },
      },
      {
        href: '/partenaires/licence/stats',
        title: 'Statistiques',
        icon: {
          element: 'b-icon',
          attributes: {
            variant: 'secondary',
            icon: 'bar-chart',
            scale: 0.8,
          },
        },
      },
    ],
  }),
  computed: {
    ...mapState('auth', ['me']),
    ...mapState('partenaires', ['resources']),
    ...mapState('dashboard', ['isBusy']),
    hasRevue() {
      if (_.isLikeFalse(this.resources)) return false;
      return _.isLikeFalse(this.resources.licence);
    },
    statistiquesUrl() {
      return toUrl('https://statistiques.cairn.info/api-refonte/auth/key', {
        key: this.me.realms.statistiques,
        realm: 'statistiques',
        registerIn: 'local-storage',
        redirect: 'https://partenaires.cairn.info/editeurs/',
      });
    },
  },
  methods: {
    onToggleCollapse(collapsed) {
      this.collapsed = collapsed;
    },
    logout() {
      this.$store.dispatch('auth/token/logout');
      this.$router.push({ name: 'auth.login' });
    },
  },
};
</script>

<style lang="scss">
#network {
  background-color: #207b75;
  font-size: 0.9rem;
  &, .nav, .nav-link, .navbar-text {
    padding: 0;
    margin: 0;
  }
  .nav-item {
    &:hover {
      background-color: #37cac0;
    }
  }
  &, .nav-link, .navbar-text {
    color: white;
  }
  .nav-link, .navbar-text {
    padding: 0.1rem 0.8rem;
    &.active {
      background-color: #2ba39b;
    }
  }
}

#root-container {
  transition: padding-left 0.3s ease;
}

#root-navbar {
  // Pompé depuis https://demo.adminkit.io/pages-clients.html
  // S'il y a besoin de + de style d'animations, utiliser animate.css ou les fonctionnalités
  // de vue.js en la matière
  .dropdown .dropdown-menu.show {
    animation-name:dropdownAnimation;
    animation-duration:.25s;
    animation-iteration-count:1;
    animation-timing-function:ease;
    animation-fill-mode:forwards
  }
}

@keyframes dropdownAnimation {
  0% {
    opacity:0;
    transform:translateY(-8px)
  }
  to {
    opacity:1;
    transform:translate(0)
  }
}
</style>
