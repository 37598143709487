<template lang="pug">
  b-modal(
    v-bind="$attrs"
    v-on="$listeners"
    :ok-disabled="$v.$invalid"
    body-class="position-static"
    @ok="onSave"
  )
    b-overlay(
      no-wrap
      rounded
      spinner-type="grow"
      spinner-variant="info"
      :show="busy"
    )
    template(#modal-title)
      | {{ isNewSubscriber ? 'Ajouter' : 'Modifier' }} un(e) abonné(e)
      br
      small(class="text-muted")  {{ resource.text }}
    b-form
      b-form-group(label="Nom")
        b-form-input(
          v-model="lastName"
          type="text"
        )
      b-form-group(label="Prénom")
        b-form-input(
          v-model="firstName"
          type="text"
        )
      b-form-group(
        label="Adresse Email"
        :state="!$v.email.$invalid"
      )
        b-form-input(
          v-model="email"
          :state="!$v.email.$invalid"
          type="text"
        )
        template(#invalid-feedback)
          template(v-if="!$v.email.required")
            | Ne doit pas être vide
          template(v-else)
            | Doit être une adresse email valide
      hr
      b-form-group(label="Référence")
        b-form-input(
          v-model="customCode"
          type="text"
        )
      b-form-group(label="Code d'activation Cairn.info" v-if="status !== 'new'")
        b-input-group
          b-form-input(
            ref="token"
            v-model="registerCairnToken"
            type="text"
            readonly
          )
          b-input-group-append
            b-button(class="rounded" variant="success" title="Copier" @click="copyToken")
              b-icon(icon="clipboard" font-scale="1")
      hr
      b-form-group(label="Accès par")
        b-form-radio-group(v-model="checkAccessType")
          b-form-radio(value="date") Dates
          b-form-radio(value="issue") Numéros
      //- Abonnement par numéro
      template(v-if="checkAccessType === 'issue'")
        b-form-group(label="Début d'abonnement" :state="!$v.beginAccess.$invalid")
          b-row
            b-col(cols="6")
              b-form-group(description="Année")
                b-form-input(
                  v-model="beginAccessIssueAnnee"
                  type="number"
                  :state="!$v.beginAccess.$invalid"
                )
            b-col(cols="6")
              b-form-group(description="Numéro")
                b-form-input(
                  v-model="beginAccessIssueNumero"
                  type="number"
                  :state="!$v.beginAccess.$invalid"
                )
          template(#invalid-feedback)
            template(v-if="!$v.beginAccess.required")
              | Ne doit pas être vide
            template(v-else)
              | Tomaison invalide
        b-form-group(label="Fin d'abonnement" :state="!$v.endAccess.$invalid")
          b-row
            b-col(cols="6")
              b-form-group(description="Année")
                b-form-input(
                  v-model="endAccessIssueAnnee"
                  type="number"
                  :state="!$v.endAccess.$invalid"
                )
            b-col(cols="6")
              b-form-group(description="Numéro")
                b-form-input(
                  v-model="endAccessIssueNumero"
                  type="number"
                  :state="!$v.endAccess.$invalid"
                )
          template(#invalid-feedback)
            template(v-if="!$v.endAccess.required")
              | Ne doit pas être vide
            template(v-else)
              | Tomaison invalide
      //- Abonnement par date
      template(v-else)
        b-form-group(label="Début d'abonnement" :state="!$v.beginAccess.$invalid")
          b-form-input(
            v-model="beginAccess"
            :state="!$v.beginAccess.$invalid"
            type="date"
          )
          template(#invalid-feedback)
            template(v-if="!$v.beginAccess.required")
              | Ne doit pas être vide
            template(v-else)
              | Doit être une date valide
        b-form-group(label="Fin d'abonnement" :state="!$v.endAccess.$invalid")
          b-form-input(
            v-model="endAccess"
            type="date"
            :state="!$v.endAccess.$invalid"
          )
          template(#invalid-feedback)
            template(v-if="!$v.endAccess.required")
              | Ne doit pas être vide
            template(v-else-if="!$v.endAccess.gtThanBeginAccess.$invalid")
              | Doit être postérieure à la date de début d'abonnement
            template(v-else)
              | Doit être une date valide
      p(class="text-muted font-italic small" v-if="modifiedAt")
        | Modifié <abbr v-b-tooltip.hover :title="modifiedAt|date('LLLL')">{{ modifiedAt.fromNow() }}</abbr>
        | <span v-if="modifiedBy"> par {{ modifiedBy }}</span>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import {
  enumeration,
  gtThan,
  isoDate,
  numeroTomaison,
} from '@/services/validators';

export default {
  inheritAttrs: false,
  props: {
    subscriber: Object,
    resource: Object,
  },
  data() {
    const isNewSubscriber = _.isLikeFalse(this.subscriber);
    return {
      busy: false,
      isNewSubscriber,
      idSubscriber: _.get('subscriber.id')(this),
      firstName: _.get('subscriber.firstName')(this),
      lastName: _.get('subscriber.lastName')(this),
      email: _.get('subscriber.email')(this),
      customCode: _.get('subscriber.customCode')(this),
      registerCairnToken: _.get('subscriber.registerCairnToken')(this),
      checkAccessType: _.get('subscriber.checkAccessType')(this) || 'date',
      beginAccess: _.get('subscriber.beginAccess')(this) || (isNewSubscriber && this.$today.format('YYYY-MM-DD')),
      endAccess: _.get('subscriber.endAccess')(this) || (
        isNewSubscriber && this.$today.add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD')
      ),
      status: _.get('subscriber.status')(this) || 'new',
      modifiedAt: _.get('subscriber.modifiedAt')(this) || null,
      modifiedBy: _.get('subscriber.modifiedBy')(this) || null,
    };
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
      checkAccessType: {
        required,
        enumeration: enumeration(['issue', 'date']),
      },
      beginAccess: this.checkAccessType === 'issue' ? {
        required,
        numeroTomaison,
      } : {
        required,
        isoDate,
      },
      endAccess: this.checkAccessType === 'issue' ? {
        required,
        numeroTomaison,
      } : {
        required,
        isoDate,
        gtThanBeginAccess: gtThan('beginAccess'),
      },
    };
  },
  computed: {
    // Gestion des débuts d'accès
    beginAccessIssue() {
      if (this.checkAccessType !== 'issue') return ['', ''];
      return this.beginAccess.split('/');
    },
    beginAccessIssueAnnee: {
      get: (vm) => vm.beginAccessIssue[0],
      set(annee) {
        this.beginAccess = `${annee}/${this.beginAccessIssueNumero}`;
      },
    },
    beginAccessIssueNumero: {
      get: (vm) => vm.beginAccessIssue[1],
      set(numero) {
        this.beginAccess = `${this.beginAccessIssueAnnee}/${numero}`;
      },
    },
    // Gestion des fins d'accès
    endAccessIssue() {
      if (this.checkAccessType !== 'issue') return ['', ''];
      return this.endAccess.split('/');
    },
    endAccessIssueAnnee: {
      get: (vm) => vm.endAccessIssue[0],
      set(annee) {
        this.endAccess = `${annee}/${this.endAccessIssueNumero}`;
      },
    },
    endAccessIssueNumero: {
      get: (vm) => vm.endAccessIssue[1],
      set(numero) {
        this.endAccess = `${this.endAccessIssueAnnee}/${numero}`;
      },
    },
  },
  methods: {
    reset() {
      this.status = 'new';
      this.firstName = null;
      this.lastName = null;
      this.idSubscriber = null;
      this.email = null;
      this.customCode = null;
      this.registerCairnToken = null;
      this.checkAccessType = null;
      this.beginAccess = null;
      this.endAccess = null;
      this.status = null;
      this.modifiedAt = null;
      this.modifiedBy = null;
    },
    copyToken() {
      this.$refs.token.select();
      document.execCommand('copy');
      this.$bvToast.toast('Jeton copié !', {
        title: 'Information',
        variant: 'success',
        autoHideDelay: 1000,
      });
    },
    async onSave(ev) {
      this.busy = true;
      ev.preventDefault(ev);
      let methodDispatch;
      let subscriber = {
        checkAccessType: this.checkAccessType,
        beginAccess: this.beginAccess,
        endAccess: this.endAccess,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        customCode: this.customCode,
      };
      if (this.isNewSubscriber) {
        methodDispatch = 'saveNewSubscribers';
        subscriber.id_resource = this.resource.value;
      } else {
        methodDispatch = 'saveSubscribers';
        subscriber.id = this.idSubscriber;
      }
      try {
        [subscriber] = await this.$api.partenaires.licence[methodDispatch]([subscriber]);
      } finally {
        this.busy = false;
      }
      this.$bvModal.hide(ev.componentId);
      this.$emit('save', subscriber);
    },
  },
};
</script>

<style lans="sass">
</style>
