// Imports from external libraries
import axios from 'axios';
import qs from 'qs';

// Import from local code
import setupAuth from './auth';
import setupUser from './user';
import setupPartenaires from './partenaires';

export default {
  install(Vue, settings, store, router) {
    const $http = axios.create({
      timeout: settings.api.timeout,
      withCredentials: false,
      baseURL: settings.api.baseURL,
      headers: {
        'Content-Type': 'application/json',
        'Attribute-Convention': 'camel-case',
      },
      paramsSerializer: (params) => qs.stringify(params, { indices: false }),
    });

    // Pour chaque requête, on va injecter le token
    $http.interceptors.request.use(async (config) => {
      Vue.prototype.$Progress.start();
      const token = localStorage.getItem('cairn:partenaires:user-token');
      // eslint-disable-next-line no-param-reassign
      if (token) config.headers.Authorization = `Bearer ${token}`;
      return config;
    });

    // Affichage des erreurs de requête
    $http.interceptors.response.use(
      async (response) => {
        Vue.prototype.$Progress.finish();
        return response.data;
      },
      async (error) => {
        Vue.prototype.$Progress.fail();
        let name = error.code;
        let description = error.message;
        const options = {};
        if (_.get('response.data.type')(error) === 'error') {
          name = error.response.data.data.name ?? error.response.statusText;
          description = error.response.data.data.description;
          // Les erreurs de quota doivent rester affichées constamment.
          if (error.response.status === 401) {
            router.push('/login');
            return Promise.reject(error);
          }
          if (error.response.status === 403) {
            options.noAutoHide = true;
          }
        }
        Vue.prototype.$notify.danger(description, name, options);
        return Promise.reject(error);
      },
    );

    // Rendre disponible axios partout dans Vue
    Vue.prototype.$http = $http;
    store.$http = $http;

    // Injection du module api
    const $api = {
      auth: setupAuth($http),
      user: setupUser($http),
      partenaires: setupPartenaires($http),
    };
    Vue.prototype.$api = $api;
    store.$api = $api;
  },
};
