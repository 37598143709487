/*
  Validateurs
*/
import { helpers } from 'vuelidate/lib/validators';

export function enumeration(list) {
  return (value) => _.includes(value)(list);
}

export function boolean(value) {
  return _.isBoolean(value);
}

export const isoDate = helpers.regex('isoDate', /^\d{4,}-[0-1]\d-[0-3]\d$/);

export const isEmptyLike = _.overSome([
  _.isObjectLike,
  _.isArrayLike,
  _.isString,
  _.isMap,
  _.isSet,
]);

export const isLikeFalse = _.overSome([
  _.isEqual(false, _),
  _.isNil,
  _.isNaN,
  _.overEvery([isEmptyLike, _.isEmpty]),
]);

export const isLikeTrue = _.negate(isLikeFalse);

export const isFile = (v) => v instanceof File;

/*
####################################################################################################
# Validateurs propre aux données cairn
####################################################################################################
*/
export const numeroTomaison = helpers.regex('numeroTomaison', /\d{4,}\/\d+(-\d+)?/);

/*
####################################################################################################
# Validateurs propre à vuelidate
####################################################################################################
*/
export function gtThan(target) {
  return helpers.withParams({ type: 'gtThan', target }, function gtThen(value, vm) {
    return value > helpers.ref(target, this, vm);
  });
}

export function ltThan(target) {
  return helpers.withParams({ type: 'ltThan', target }, function ltThen(value, vm) {
    return value < helpers.ref(target, this, vm);
  });
}
