<template lang="pug">
  b-row(class="m-3"): b-col: b-overlay(
    spinner-variant="info"
    opacity="0.20"
    :show="busy"
  )
    b-row
      b-col(cols="8")
        b-form-group(
          v-if="resources.length > 0"
          :label="resourceType === 'licence' ? 'Revue' : 'Institution'"
          label-for="subscribers-resource"
          label-cols="1"
        )
          b-form-select(
            id="subscribers-resource"
            v-model="$query.resource"
            :options="resources"
          )
      b-col(cols="2" offset="2")
        b-button-group(class="d-flex")
          b-button(
            variant="success"
            :title="'Ajouter un ' + subscriberLabel"
            v-b-modal.edit-subscriber
          )
            b-icon(icon="person-plus") Ajouter
          b-button(
            variant="outline-success"
            :title="'Importer des ' + subscriberLabel + 's'"
            v-b-modal.import-subscribers
          )
            b-icon(icon="upload") Importer
    b-row
      b-col(cols="8")
        b-form-radio-group(
          v-model="$query.status"
          buttons button-variant="outline-dark"
          class="w-100"
        )
          b-form-radio(value=".")
            | tous les {{ subscriberLabel|pluralize }}
            | ({{ countSubscribersByStatus.all }})
          b-form-radio(value="new")
            | {{ subscriberLabel|pluralize(countSubscribersByStatus.new) }} à inviter
            | ({{ countSubscribersByStatus.new }})
          b-form-radio(value="activated")
            | {{ subscriberLabel|pluralize(countSubscribersByStatus.activated) }} actifs
            | ({{ countSubscribersByStatus.activated }})
          b-form-radio(value="expired")
            | {{ subscriberLabel|pluralize(countSubscribersByStatus.expired) }} expirés
            | ({{ countSubscribersByStatus.expired }})
          b-form-radio(value="pending")
            | {{ subscriberLabel|pluralize(countSubscribersByStatus.pending) }} en attente
            | ({{ countSubscribersByStatus.pending }})
          b-form-radio(value="disabled")
            | {{ subscriberLabel|pluralize(countSubscribersByStatus.disabled) }} inactifs
            | ({{ countSubscribersByStatus.disabled }})
      b-col(cols="2" offset="2")
        b-dropdown(
          block
          :variant="selectedSubscribers.length ? 'primary' : ''"
          :disabled="!selectedSubscribers.length"
          menu-class="w-100"
        )
          template(#button-content)
            | Agir sur {{ selectedSubscribers.length }} {{ subscriberLabel|pluralize(selectedSubscribers) }}
          b-dropdown-text(v-if="!canPendingSubscribers" variant="secondary")
            i Désactiver car les {{ subscriberLabel|pluralize }} sont désactivés ou sont déjà actif sur cairn
          b-dropdown-item(:disabled="!canPendingSubscribers" v-b-modal.pending-subscribers) Inviter sur Cairn
          b-dropdown-divider
          b-dropdown-text(v-if="hasMixedTypesForSubscribers" variant="secondary")
            i Désactiver car les types d'accès des {{ subscriberLabel|pluralize }} ne sont pas homogènes
          b-dropdown-item(
            v-b-modal.edit-access-subscribers
            :disabled="hasMixedTypesForSubscribers"
          ) Modifier la période d'accès
          b-dropdown-divider
          b-dropdown-item(@click="onExportToExcel(selectedSubscribers)") Exporter en Excel
          b-dropdown-divider
          b-dropdown-item(variant="warning" v-b-modal.disable-subscribers) Désactiver
          b-dropdown-item(variant="danger" v-b-modal.delete-subscribers) Supprimer
    b-row(class="mt-4"): b-col(cols="12")
      b-table(
        id="list-subscribers"
        ref="listSubscribers"
        primary-key="id"
        hover
        responsive
        bordered
        striped
        show-empty
        selectable
        select-mode="multi"
        selected-variant="primary"
        @row-selected="onRowSelected"
        sticky-header="70vh"
        head-variant="dark"
        foot-variant="dark"
        :foot-clone="allSubscribers.length > 10"
        :items="filteredSubscribers"
        :fields="fields"
        :sort-by.sync="$query.sortBy"
        :sort-desc.sync="$query.sortDesc"
      )
        template(#empty)
          div(class="text-center text-muted") Aucun accès {{ subscriberLabel }} pour cette institution !
        template(#emptyfiltered)
          div(class="text-center text-muted") Aucun {{ subscriberLabel }} ne répond aux critères demandés !
        template(#top-row="{ fields }")
          template(v-for="(field, index) in fields")
            td(v-if="field.key === 'selected'")
              //- Selection de toutes les lignes
              b-button(
                :title=`
                  selectedSubscribers.length !== filteredSubscribers.length ?
                  'Sélectionner tout' :
                  'Ne sélectionner aucun'
                `
                :variant="variantForSelectedAllRowsButton"
                @click="onSelectedAllRows"
              )
                b-icon(icon="check2-all")
            td(v-else-if="!field.isFilterable" :class="field.class")
            td(v-else :key="field.key" :class="field.class")
              //- Filtre d'énumération
              b-form-select(
                v-if="field.type === 'enum'"
                :options="field.enum"
                v-model="$query[field.key]"
              )
              //- Filtre par regexp
              b-form-input(
                v-else
                v-model.lazy="$query[field.key]"
                :placeholder="field.label"
              )
        template(#cell(index)="{ index }")
          | {{ index + 1 }}
        template(#cell(beginAccess)="data")
          template(v-if="data.item.checkAccessType === 'date'") {{ data.value|date('YYYY-MM-DD', 'L') }}
          template(v-else) {{ data.value }}
        template(#cell(endAccess)="data")
          template(v-if="data.item.checkAccessType === 'date'") {{ data.value|date('YYYY-MM-DD', 'L') }}
          template(v-else) {{ data.value }}
        template(#cell(selected)="{ rowSelected }")
          div(class="text-center" :class="{invisible: !rowSelected}")
            b-icon(icon="check2")
        template(#cell(actions)="data")
          b-button-group(class="d-flex" size="sm")
            b-button(
              variant="outline-info"
              title="Modifier"
              v-b-modal.edit-subscriber
              @click="currentSubscriber = data.item.id"
            )
              b-icon(icon="pencil-square")
            b-button(
              v-if="data.item.status === 'new'"
              variant="outline-success"
              title="Inviter"
              @click="selectedSubscriber = data.item"
              v-b-modal.pending-subscriber
            )
              b-icon(icon="envelope-fill")
            b-button(
              v-if="['pending', 'disabled'].includes(data.item.status)"
              variant="outline-success"
              title="Relancer"
              @click="selectedSubscriber = data.item"
              v-b-modal.re-pending-subscriber
            )
              b-icon(icon="arrow-clockwise")
            b-button(
              v-if="data.item.status === 'activated'"
              variant="outline-danger"
              title="Désactiver"
              @click="selectedSubscriber = data.item"
              v-b-modal.disable-subscriber
            )
              b-icon(icon="slash-circle")
        template(#cell()="data")
          template(v-if="data.field.type === 'enum'")
            | {{ data.field.enum[data.value] }}
          template(v-else)
            | {{ data.value }}
    b-row(v-if="lastedEditedSubscriber"): b-col: p(class="text-muted font-italic small")
      | Dernière modification
      abbr(v-b-tooltip.hover :title="lastedEditedSubscriber.modifiedAt|date('LLLL')")
        |  {{ lastedEditedSubscriber.modifiedAt.fromNow() }}
      | .
    template(v-if="currentResource")
      //- Modale sur l'importation par log
      modal-import-subscribers(
        id="import-subscribers"
        @save="onImportNewSubscribers"
        :resource-type="resourceType"
        :resource="currentResource"
        :resources="resources"
      )
      //- Modale sur l'édition d'un abonné particulier
      component(
        v-if="currentSubscriber"
        :is="ModalEditSubscriber"
        id="edit-subscriber"
        :key="currentSubscriber.id"
        :subscriber="currentSubscriber"
        :resource="currentResource"
        @save="onSaveSubscriber"
        @hidden="currentSubscriber = ''"
      )
      //- Modale sur le basculement d'abonnés vers "pending", avec l'envoi de mail qui l'accompagne
      b-modal(
        v-if="selectedSubscriber"
        id="pending-subscriber"
        title="Attention"
        header-bg-variant="success"
        header-text-variant="white"
        @ok="onPendingSubscribers([selectedSubscriber])"
      ): p
        | Vous êtes sur le point d'envoyer à
        | #[strong {{ selectedSubscriber.lastName }} {{ selectedSubscriber.firstName }}]
        | une invitation à activer son accès à {{ resourceType === 'licence' ? "la revue" : "l'institution" }}
        | #[em {{ currentResource.text }}] sur Cairn.info.
        | Cette action est immédiate.
      b-modal(
        v-if="selectedSubscriber"
        id="re-pending-subscriber"
        title="Attention"
        header-bg-variant="success"
        header-text-variant="white"
        @ok="onPendingSubscribers([selectedSubscriber])"
      ): p
        | Vous êtes sur le point d'envoyer de nouveau à
        | #[strong {{ selectedSubscriber.lastName }} {{ selectedSubscriber.firstName }}]
        | une invitation à activer son accès à {{ resourceType === 'licence' ? "la revue" : "l'institution" }}
        | #[em {{ currentResource.text }}] sur Cairn.info.
        | Cette action est immédiate.
      b-modal(
        v-if="selectedSubscribers"
        id="pending-subscribers"
        title="Attention"
        header-bg-variant="success"
        header-text-variant="white"
        @ok="onPendingSubscribers(selectedSubscribers)"
      ): p
        | Vous êtes sur le point d'envoyer à #[strong {{ selectedSubscribers.length }}]
        | {{ subscriberLabel|pluralize(selectedSubscribers) }} une invitation
        | à activer un accès à {{ resourceType === 'licence' ? "la revue" : "l'institution" }}
        | #[em {{ currentResource.text }}] sur Cairn.info.
        | Cette action est immédiate.
      //- Modale sur le changement de date d'accès par lot
      modal-edit-access-subscribers(
        v-if="selectedSubscribers"
        id="edit-access-subscribers"
        :resource-type="resourceType"
        :subscribers="selectedSubscribers"
        :resource="currentResource"
        @save="onSaveSubscribers"
      )
      //- Modale sur la désactivation
      b-modal(
        id="disable-subscriber"
        title="Attention"
        header-bg-variant="warning"
        v-if="selectedSubscriber"
        @ok="onDisableSubscribers([selectedSubscriber])"
      ): p
        | Vous êtes sur le point de désactiver l'accès sur Cairn.info à
        | {{ resourceType === 'licence' ? "la revue" : "l'institution" }} #[em {{ currentResource.text }}]
        | pour #[strong {{ selectedSubscriber.lastName }} {{ selectedSubscriber.firstName }}].
        | Cette action est immédiate et irréversible.
      b-modal(
        id="disable-subscribers"
        title="Attention"
        header-bg-variant="warning"
        v-if="selectedSubscribers"
        @ok="onDisableSubscribers(selectedSubscribers)"
      ): p
        | Vous êtes sur le point de désactiver l'accès sur Cairn.info à
        | {{ resourceType === 'licence' ? "la revue" : "l'institution" }} #[em {{ currentResource.text }}]
        | pour #[strong {{ selectedSubscribers.length }}] {{ subscriberLabel|pluralize(selectedSubscribers) }}.
        | Cette action est immédiate.
      //- Modale sur la suppression
      b-modal(
        id="delete-subscriber"
        title="Attention"
        header-bg-variant="danger"
        header-text-variant="white"
        v-if="selectedSubscriber"
        @ok="onRemoveSubscribers([selectedSubscriber])"
      ): p
        | Vous êtes sur le point de supprimer l'accès sur Cairn.info à
        | {{ resourceType === 'licence' ? "la revue" : "l'institution" }} #[em {{ currentResource.text }}]
        | pour #[strong {{ selectedSubscriber.lastName }} {{ selectedSubscriber.firstName }}].
        | Cette action est immédiate et irréversible.
      b-modal(
        id="delete-subscribers"
        title="Attention"
        header-bg-variant="danger"
        header-text-variant="white"
        v-if="selectedSubscribers"
        @ok="onRemoveSubscribers(selectedSubscribers)"
      ): p
        | Vous êtes sur le point de supprimer l'accès sur Cairn.info à #[em {{ currentResource.text }}]
        | pour #[strong {{ selectedSubscribers.length }}] {{ subscriberLabel|pluralize(selectedSubscribers) }}.
        | Cette action est immédiate et irréversible.
</template>

<script>
import { saveAs } from 'file-saver';
import ModalImportSubscribers from './modal-import-subscribers.vue';
import ModalEditInstitutionSubscriber from './institution/modal-edit-subscriber.vue';
import ModalEditLicenceSubscriber from './licence/modal-edit-subscriber.vue';
import ModalEditAccessSubscribers from './modal-edit-access-subscribers.vue';

export default {
  metaInfo() {
    let title = this.$query.resource;
    if (this.currentResource) {
      title = this.currentResource.text;
    }
    return {
      title: `Gestion accès pour ${title}`,
    };
  },
  components: {
    ModalImportSubscribers,
    ModalEditInstitutionSubscriber,
    ModalEditLicenceSubscriber,
    ModalEditAccessSubscribers,
  },
  props: {
    resourceType: {
      type: String,
      // required: true,
      default: 'institution',
    },
  },
  data() {
    // eslint-disable-next-line prefer-rest-params
    return {
      fields: [
        {
          key: 'selected',
          label: '',
        },
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'lastName',
          label: 'Nom',
          sortable: true,
          isFilterable: true,
          inExcel: true,
        },
        {
          key: 'firstName',
          label: 'Prénom',
          sortable: true,
          isFilterable: true,
          inExcel: true,
        },
        {
          key: 'email',
          label: 'Adresse email',
          sortable: true,
          isFilterable: true,
          inExcel: true,
        },
        {
          key: 'customCode',
          label: 'Référence',
          sortable: true,
          isFilterable: true,
          inExcel: true,
        },
        {
          key: 'beginAccess',
          label: "Début d'accès",
          sortable: true,
          isFilterable: true,
          type: 'date',
          inExcel: true,
        },
        {
          key: 'endAccess',
          label: "Fin d'accès",
          sortable: true,
          isFilterable: true,
          type: 'date',
          inExcel: true,
        },
        {
          key: 'status',
          label: 'Accès Cairn.info',
          sortable: true,
          isFilterable: true,
          inExcel: true,
          type: 'enum',
          enum: {
            new: 'À inviter',
            pending: 'En attente',
            activated: 'Actif',
            disabled: 'Inactif',
          },
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      ModalEditSubscriber: this.resourceType === 'licence'
        ? 'ModalEditLicenceSubscriber'
        : 'ModalEditInstitutionSubscriber',
      selectedSubscriber: null,
      selectedSubscribers: [],
      resources: [],
      allSubscribers: [],
      filteredSubscribers: [],
      busy: false,
    };
  },
  computed: {
    subscriberLabel: (vm) => (vm.resourceType === 'licence' ? 'abonné' : 'membre'),
    apiModule: (vm) => (vm.resourceType === 'licence' ? vm.$api.partenaires.licence : vm.$api.partenaires.institution),
    editableFields: (vm) => _.filter('isEditable')(vm.fields),
    enumSubscribersStatus: (vm) => _.find({ key: 'status' })(vm.fields).enum,
    currentResource() {
      return _.find({ value: _.toNumber(this.$query.resource) })(this.resources);
    },
    currentSubscriber: {
      get() {
        if (!this.$query.id_subscriber) return {};
        let subscriber = _.find({ id: _.toNumber(this.$query.id_subscriber) })(this.filteredSubscribers);
        if (_.isLikeFalse(subscriber)) subscriber = {};
        return subscriber;
      },
      set(subscriber) {
        // eslint-disable-next-line no-param-reassign
        if (_.isLikeFalse(subscriber)) subscriber = '';
        // eslint-disable-next-line no-param-reassign
        if (_.isPlainObject(subscriber) && _.has('id')(subscriber)) subscriber = subscriber.id;
        this.$query.id_subscriber = subscriber;
      },
    },
    countSubscribersByStatus() {
      // Retourne le nombre de membres par statut
      return _.mergeAll([
        // Valeur par défaut
        {
          all: this.allSubscribers.length,
          disabled: 0,
          new: 0,
          pending: 0,
          activated: 0,
          expired: 0,
        },
        // Récupération des données réelles
        _.countBy('status')(this.allSubscribers),
        {
          expired: _.sumBy(this.isExpiredSubscriber)(this.allSubscribers),
          activated: _.sumBy(
            (subscriber) => subscriber.status === 'activated' && !this.isExpiredSubscriber(subscriber),
          )(this.allSubscribers),
        },
      ]);
    },
    lastedEditedSubscriber() {
      return _.reduce((s1, s2) => {
        if (s1.modifiedAt > s2.modifiedAt) return s1;
        return s2;
      }, 0)(this.allSubscribers);
    },
    hasMixedTypesForSubscribers() {
      return _.keys(_.countBy('checkAccessType')(this.selectedSubscribers)).length !== 1;
    },
    canPendingSubscribers() {
      return _.every((a) => a.status === 'new')(this.selectedSubscribers);
    },
    variantForSelectedAllRowsButton() {
      if (this.selectedSubscribers.length === this.filteredSubscribers.length) {
        return 'primary';
      }
      if (this.selectedSubscribers.length) {
        return 'outline-primary';
      }
      return 'outline-secondary';
    },
    excelFields() {
      return _.flow(
        _.filter((field) => field.inExcel === true),
        _.map((field) => [field.key, field.label]),
        _.fromPairs,
      )(this.fields);
    },
  },
  watch: {
    '$query.resource': function queryResource() {
      return this.fetchSubscribers();
    },
    allSubscribers() {
      return this.getFilteredSubscribers();
    },
  },
  async created() {
    // Une méthode couplée à un watcher est préférée à computed, parce que les accès à $query puis à
    // un de ses attributs lance le traitement deux fois.
    console.log(this.$props);
    _.flow(
      _.filter('isFilterable'),
      _.map((o) => this.$watch(() => this.$query[o.key], this.getFilteredSubscribers)),
    )(this.fields);
    // Récupération de la liste des ressources disponible à l'utilisateur
    await this.fetchResources();
    if (!this.$query.resource.length) {
      // eslint-disable-next-line prefer-destructuring
      this.$query.resource = this.resources[0].value;
      // Implicitement, appelle fetchSubscribers()
    } else {
      // Récupération des accès depuis l'api
      await this.fetchSubscribers();
    }
    // Ouverture de la modale si un membre est demandé dans l'url
    if (this.$query.id_subscriber) {
      this.$bvModal.show('edit-subscriber');
    }
  },
  methods: {
    isExpiredSubscriber(subscriber) {
      if (subscriber.status !== 'activated') return false;
      if (subscriber.checkAccessType !== 'date') return false;
      return this.$date(subscriber.endAccess).isBetween(this.$today.subtract(3, 'month'), this.$today);
    },
    refreshTable() {
      this.$refs.listSubscribers.clearSelected();
      this.getFilteredSubscribers();
    },
    async fetchResources() {
      const resources = await this.$store.dispatch('partenaires/getResources', { type: this.resourceType });
      this.resources = _.map((resource) => ({
        text: resource.label,
        value: resource.id,
        type: resource.type,
        key: resource.key,
      }))(resources);
      return this.resources;
    },
    async fetchSubscribers() {
      try {
        this.busy = true;
        const subscribers = await this.apiModule.getSubscribers([this.$query.resource]);
        this.allSubscribers = subscribers;
        return this.allSubscribers;
      } finally {
        this.busy = false;
      }
    },
    getFilteredSubscribers() {
      // Construit une regexp dans tous les cas
      const buildRegexp = (string) => {
        try {
          return RegExp(string, ['imus']);
        } catch {
          // On construit une regexp en utilisant la valeur en string brut
          return RegExp(_.escapeRegExp(string), ['imus']);
        }
      };
      const buildString = _.flow(_.deburr, buildRegexp);
      // Construction du tableau de fonctions qui seront appelées les unes après les autres pour filtrer les résultats
      const doFilters = _.flow(
        // Récupération des champs filtrables
        _.filter('isFilterable'),
        // Récupération des champs dans l'url
        _.map((o) => ({ [o.key]: _.get(o.key)(this.$query) })),
        // Filtrage des champs vides
        _.mergeAll,
        _.pickBy(_.identity),
        // Construction des regexps
        _.mapValues(buildString),
        _.toPairs,
        // Le tableau de fonctions qui permettra de filtrer les accès
        _.map(([key, regexp]) => _.filter((subscriber) => {
          let attr = _.get(key)(subscriber);
          // console.log(key);
          // let attr = subscriber[key];
          attr = _.toString(attr);
          attr = _.deburr(attr);
          if (key === 'status' && attr === 'activated') {
            if (this.$query.status === 'activated') return !this.isExpiredSubscriber(subscriber);
            if (this.$query.status === 'expired') return this.isExpiredSubscriber(subscriber);
          }
          return regexp.test(attr);
        })),
      )(this.fields);
      // Application du tableau de fonctions
      const filteredSubscribers = _.flow(doFilters)(this.allSubscribers);
      this.filteredSubscribers = filteredSubscribers;
      return filteredSubscribers;
    },
    // Actions sur une liste d'membres
    saveSubscribers(subscribers) {
      _.forEach(this.saveSubscriber)(subscribers);
      this.refreshTable();
    },
    saveSubscriber(updatedSubscriber) {
      const subscriberIndex = _.findIndex({ id: updatedSubscriber.id })(this.allSubscribers);
      const subscriber = this.allSubscribers[subscriberIndex];
      if (_.isEmpty(subscriber)) {
        this.allSubscribers.push(updatedSubscriber);
      } else {
        this.$set(this.allSubscribers, subscriberIndex, _.mergeAll([
          subscriber,
          updatedSubscriber,
        ]));
      }
    },
    // ******************************************
    // Réaction aux events
    // ******************************************
    onRowSelected(rows) {
      this.selectedSubscribers = rows;
    },
    onSelectedAllRows() {
      if (this.filteredSubscribers.length === this.selectedSubscribers.length) {
        this.$refs.listSubscribers.clearSelected();
      } else {
        this.$refs.listSubscribers.selectAllRows();
      }
    },
    // Actions sur un nouvel membre à ajouter
    onImportNewSubscribers(subscribers) {
      this.$bvToast.toast(`${subscribers.length} lignes traitées !`, {
        title: 'Opération réussie !',
        variant: 'success',
        autoHideDelay: 2500,
      });
      this.saveSubscribers(subscribers);
    },
    // Actions sur un membre précis
    onSaveSubscriber(subscriber) {
      this.$bvToast.toast('Le membre a bien été modifié !', {
        title: 'Opération réussie !',
        variant: 'success',
        autoHideDelay: 2500,
      });
      this.saveSubscriber(subscriber);
    },
    onSaveSubscribers(subscribers) {
      this.saveSubscribers(subscribers);
    },
    async onDisableSubscribers(subscribers) {
      const dataSubscribers = _.map(_.flow(
        _.pick(['id']),
        _.merge({ status: 'disabled' }),
      ))(subscribers);
      const disabledSubscribers = await this.apiModule.saveSubscribers(dataSubscribers);
      this.$bvToast.toast(`${disabledSubscribers.length} membres désactivés !`, {
        title: 'Opération réussie !',
        variant: 'success',
        autoHideDelay: 2500,
      });
      _.forEach(this.saveSubscriber)(disabledSubscribers);
      this.refreshTable();
    },
    async onRemoveSubscribers(subscribers) {
      // Appel à l'api pour suppression
      const removedSubscribers = await this.apiModule.removeSubscribers(
        _.map(_.get('id'))(subscribers),
      );
      // Notification
      this.$bvToast.toast(`${removedSubscribers.length} membres supprimés !`, {
        title: 'Opération réussie !',
        variant: 'success',
        autoHideDelay: 2500,
      });
      // Suppression des membres supprimés du tableau
      const idSubscribers = _.map(_.get('id'))(removedSubscribers);
      this.allSubscribers = _.filter((s) => _.indexOf(s.id)(idSubscribers) < 0)(this.allSubscribers);
      this.refreshTable();
    },
    async onPendingSubscribers(subscribers) {
      const dataSubscribers = _.map(_.flow(
        _.pick(['id']),
        _.merge({ status: 'pending' }),
      ))(subscribers);
      const pendingSubscribers = await this.apiModule.saveSubscribers(dataSubscribers);
      this.$bvToast.toast(`${pendingSubscribers.length} invitations envoyées !`, {
        title: 'Opération réussie !',
        variant: 'success',
        autoHideDelay: 2500,
      });
      _.forEach(this.saveSubscriber)(pendingSubscribers);
      this.refreshTable();
    },
    async onExportToExcel(subscribers) {
      const formattedSubscribers = _.map(_.flow(
        _.pick(_.keys(this.excelFields)),
        _.update('status', (v) => this.enumSubscribersStatus[v]),
        _.mapKeys((k) => this.excelFields[k]),
      ))(subscribers);
      const excel = await this.$api.partenaires.spreadsheet.fromSubscribers(formattedSubscribers);
      const blob = new Blob([excel], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, 'export-partenaires.xlsx');
    },
  },
};
</script>

<style lang="scss">
</style>
